<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/content.svg" alt />
        <span class="center" @click="goBack">新闻管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">新建新闻</span>
      </div>
    </div>
    <el-card>
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="60px"
        style="text-align:left"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title" style="width: 100%" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="作者" prop="creater">
          <el-input v-model="ruleForm.creater" style="width: 100%" placeholder="请输入创建人姓名"></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="type">
          <el-select
            v-model="ruleForm.type"
            filterable
            clearable
            allow-create
            style="width: 100%"
            default-first-option
            placeholder="请选择文章标签"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="create_time">
          <el-date-picker
            v-model="ruleForm.create_time"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="封面" prop="cover">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="successFile"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.cover" :src="ruleForm.cover" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span
              slot="tip"
              class="el-upload__tip"
              style="display: table-row-group;color: chocolate;"
            >*340px*150px</span>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="类型" prop="type">
          <el-autocomplete
            style="width: 600px"
            class="inline-input"
            v-model="ruleForm.type"
            :fetch-suggestions="querySearch"
            placeholder="请输入类型"
            @select="handleSelect"
            clearable
          ></el-autocomplete>
        </el-form-item>-->

        <el-form-item label="摘要" prop="remark">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            style="width:100%"
            maxlength="150"
            rows="4"
            show-word-limit
            placeholder="请输入摘要"
          ></el-input>
        </el-form-item>

        <el-form-item label="内容" prop="text">
          <TinyEditor v-model="ruleForm.content" :height="400" :configStr="''"></TinyEditor>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { api } from '../../api/base/index';
// import baseUrl from '../../config/config';
// 新增接口
export const ContentAdd = api('/content')('home.news.add.json');
export const ContentTypes = api('/content')('home.news.types.json');
export default {
  components: {
    TinyEditor: () => import('../../components/tinyEditor'),
  },
  data () {
    return {
      ruleForm: {
        title: '',
        type: '',
        content: '',
        remark: '',
        cover: '',
        tags: '',
        create_time: '',
        creater: '',
      },
      rules: {
        title: [{ required: true, message: '请输入新闻标题', trigger: 'blur' }],
        type: [{ required: true, message: '请输入内容类型', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        remark: [
          { required: true, message: '请输入内容备注', trigger: 'blur' },
        ],
        create_time: [
          { required: true, message: '请选择时间', trigger: 'blur' },
        ],
        creater: [
          { required: true, message: '请输入创建人姓名', trigger: 'blur' },
        ],
      },
      // restaurants: [],
      fileList: [],
      action: '',
      options: [],
    };
  },
  props: {
    dialogVisible: Boolean,
  },
  created () {
    this.getTypeList();
    this.action = window.location.origin + '/enterprise.upload.json';
    // this.getCurrentTime();
    // console.log(this.ruleForm.create_time);
    console.log(this.action);
  },
  methods: {
    // getCurrentTime() {
    //   //获取当前时间并打印
    //   var _this = this;
    //   let yy = new Date().getFullYear();
    //   let mm = new Date().getMonth() + 1;
    //   let dd = new Date().getDate();
    //   let hh = new Date().getHours();
    //   let mf =
    //     new Date().getMinutes() < 10
    //       ? '0' + new Date().getMinutes()
    //       : new Date().getMinutes();
    //   let ss =
    //     new Date().getSeconds() < 10
    //       ? '0' + new Date().getSeconds()
    //       : new Date().getSeconds();
    //   _this.ruleForm.create_time =
    //     yy + '/' + mm + '/' + dd + ' ' + hh + ':' + mf + ':' + ss;
    //   console.log(_this.ruleForm.create_time);
    // },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ContentAdd(this.ruleForm).then(() => {
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    timeChange (date) {
      console.log(date);
    },
    beforeAvatarUpload (file) {
      console.log(file);
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.cover = res.data;
      console.log(this.ruleForm.cover);
      this.filePath = res.data;
    },
    close (formName) {
      this.$refs[formName].resetFields();
    },
    goBack () {
      window.history.back(-1);
    },
    async getTypeList () {
      const res = await ContentTypes();
      console.log(res);
      this.options = res.map((it) => {
        return { value: it.type };
      });
    },
    // querySearch(queryString, cb) {
    //   var restaurants = this.restaurants;
    //   var results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants;
    //   // 调用 callback 返回建议列表的数据
    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (restaurant) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },
    // handleSelect(item) {
    //   console.log(item);
    // },
  },
};
</script>
<style lang="scss" scoped></style>
